// src/components/Activities/ActivityManager.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ActivityForm from './activityForm';
import ActivityTable from './activityTable';
import '../../styles/Activities/Activity.css';
import config from '../../config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

function ActivityManager() {
    const [activities, setActivities] = useState([]);
    const [currentActivity, setCurrentActivity] = useState(null);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchActivities();
    }, []);

    const fetchActivities = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/activities`);
            setActivities(response.data);
        } catch (error) {
            console.error('Error fetching activities:', error);
            setError('Failed to fetch activities.');
        }
    };

    const handleAddActivity = () => {
        setCurrentActivity(null);
        setIsFormOpen(true);
    };

    const handleEditActivity = (activity) => {
        setCurrentActivity(activity);
        setIsFormOpen(true);
    };

    const handleDeleteActivity = async (activityId) => {
        try {
            await axios.delete(`${API_BASE_URL}/api/activities/${activityId}`);
            setActivities(activities.filter(activity => activity.id !== activityId));
        } catch (error) {
            console.error('Error deleting activity:', error);
            setError('Failed to delete activity.');
        }
    };

    const handleFormSubmit = async (activity) => {
        try {
            let response;
            if (currentActivity) {
                response = await axios.put(`${API_BASE_URL}/api/activities/${currentActivity.id}`, activity);
                setActivities(activities.map(act => act.id === currentActivity.id ? response.data : act));
            } else {
                response = await axios.post(`${API_BASE_URL}/api/activities`, activity);
                setActivities([...activities, response.data]);
            }
            setIsFormOpen(false);
        } catch (error) {
            console.error('Error submitting activity:', error);
            setError('Failed to submit activity.');
        }
    };

    return (
        <div className="activity-management-container">
            {error && <div className="error-message">{error}</div>}
            <div className="category-button-container">
                <button onClick={handleAddActivity} className="add-activity-button">Add Activity</button>
            </div>
            <ActivityTable
                activities={activities}
                handleEditActivity={handleEditActivity}
                handleDeleteActivity={handleDeleteActivity}
            />
            {isFormOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setIsFormOpen(false)}>&times;</span>
                        <ActivityForm
                            currentActivity={currentActivity}
                            handleFormSubmit={handleFormSubmit}
                            setIsFormOpen={setIsFormOpen}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ActivityManager;
