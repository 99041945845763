import React from 'react';

function UserTable({ users, handleEditUser, handleDeleteUser }) {
    return (
        <table className="user-table">
            <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {users.map(user => (
                    <tr key={user.id}>
                        <td>{user.first_name}</td>
                        <td>{user.last_name}</td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>
                        <td>{user.is_active ? 'Active' : 'Inactive'}</td>
                        <td>
                            <button onClick={() => handleEditUser(user)} className="edit-button">Edit</button>
                            <button onClick={() => handleDeleteUser(user.id)} className="delete-button">Delete</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default UserTable;
