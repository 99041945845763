import React from 'react';

function ActivityTable({ activities, handleEditActivity, handleDeleteActivity }) {
    return (
        <table className="activity-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {activities.map(activity => (
                    <tr key={activity.id}>
                        <td>{activity.name}</td>
                        <td>{activity.price}</td>
                        <td>
                            <button onClick={() => handleEditActivity(activity)} className="edit-button">Edit</button>
                            <button onClick={() => handleDeleteActivity(activity.id)} className="delete-button">Delete</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default ActivityTable;
