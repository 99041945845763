// src/components/Settings/Settings.js
import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import MenuManager from '../Menu/menuManager';
import ActivityManager from '../Activities/activityManager';
import StaffManagement from '../User/User';

const Settings = () => {
    return (
        <div>
            <h1>Settings</h1>
            <ul>
                <li><Link to="/settings/item">Manage Menu Items</Link></li>
                <li><Link to="/settings/activity">Manage Activity</Link></li>
                <li><Link to="/settings/user">Manage Staff</Link></li>
            </ul>
            <Routes>
                <Route path="item" element={<MenuManager />} />
                <Route path="activity" element={<ActivityManager />} />
                <Route path="user" element={<StaffManagement />} />
            </Routes>
        </div>
    );
};

export default Settings;
