// src/components/restaurant/MenuItemForm.js

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import config from '../../config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

function MenuItemForm({ currentItem, categories, handleFormSubmit, setIsFormOpen }) {
    const [item, setItem] = useState({
        name: '',
        price: '',
        category_id: ''
    });

    useEffect(() => {
        if (currentItem) {
            setItem(currentItem);
        }
    }, [currentItem]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setItem({ ...item, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (currentItem) {
                await axiosInstance.put(`${API_BASE_URL}/api/items/${currentItem.id}`, item);
            } else {
                await axiosInstance.post(`${API_BASE_URL}/api/items`, item);
            }
            handleFormSubmit(item);
        } catch (error) {
            console.error('Error submitting item form:', error);
        }
        setItem({ name: '', price: '', category_id: '' }); // Reset form fields
        setIsFormOpen(false);
    };

    return (
        <div className="item-form-container">
            <form onSubmit={handleSubmit} className="item-form">
                <label>
                    Item Name:
                    <input
                        type="text"
                        name="name"
                        value={item.name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Price:
                    <input
                        type="number"
                        name="price"
                        value={item.price}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Category:
                    <select name="category_id" value={item.category_id} onChange={handleChange} required>
                        <option value="">Select a Category</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </select>
                </label>
                <div className="form-controls">
                    <button type="submit" className="submit-button">Submit</button>
                    <button type="button" className="cancel-button" onClick={() => setIsFormOpen(false)}>Cancel</button>
                </div>
            </form>
        </div>
    );
}

export default MenuItemForm;
