// src/components/basket/PaymentMethods.js

import React from 'react';

function PaymentMethods({ paymentMethod, setPaymentMethod }) {
    return (
        <div className="payment-methods">
            <button
                onClick={() => setPaymentMethod('cash')}
                className={`payment-method-button ${paymentMethod === 'cash' ? 'selected' : ''}`}
            >
                Cash
            </button>
            <button
                onClick={() => setPaymentMethod('mobile')}
                className={`payment-method-button ${paymentMethod === 'mobile' ? 'selected' : ''}`}
            >
                Mobile Pay
            </button>
        </div>
    );
}

export default PaymentMethods;
