import React, { useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import '../../styles/Login/Login.css';
import config from '../../config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

function Login({ setAuthenticated }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axiosInstance.post(`${API_BASE_URL}/api/users/login`, { email, password });
            console.log('Login successful:', response.data);
            localStorage.setItem('authenticated', 'true');
            localStorage.setItem('username', response.data.username); // Store the username
            localStorage.setItem('userId', response.data.userId); // Store the user ID
            setAuthenticated(true);
            navigate('/');
        } catch (error) {
            console.error('Login error:', error.response ? error.response.data : error.message);
            setError('Failed to login. Please check your email and password.');
        }
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <h2>Login</h2>
                {error && <div className="error-message">{error}</div>}
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" value={email} onChange={e => setEmail(e.target.value)} required />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input type="password" id="password" value={password} onChange={e => setPassword(e.target.value)} required />
                </div>
                <button type="submit" className="login-button">Login</button>
                <div className="form-group">
                    <a href="/forgot-password" className="forgot-password">Mot de passe oublié ?</a>
                </div>
                <div className="footer">
                    Powered by Weïbiland © 2024
                </div>
            </form>
        </div>
    );
}

export default Login;
