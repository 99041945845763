// src/components/restaurant/MenuItemTable.js

import React, { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import MenuItemForm from './menuItemForm';
import MenuCategoryForm from './menuCategoryForm';
import '../../styles/Menu/menuItemTable.css';
import config from '../../config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

function MenuItemTable({ handleEditItem, handleDeleteItem, handleEditCategory, handleDeleteCategory }) {
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [isCategoryFormOpen, setIsCategoryFormOpen] = useState(false);
    const [isItemFormOpen, setIsItemFormOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);

    useEffect(() => {
        fetchItemsAndCategories();
    }, []);

    const fetchItemsAndCategories = async () => {
        try {
            const itemsResponse = await axiosInstance.get(`${API_BASE_URL}/api/items`);
            const categoriesResponse = await axiosInstance.get(`${API_BASE_URL}/api/categories`);
            setItems(itemsResponse.data);
            setCategories(categoriesResponse.data);
        } catch (error) {
            console.error('Error fetching items and categories:', error);
        }
    };

    const getCategoryName = (categoryId) => {
        const category = categories.find(cat => cat.id === categoryId);
        return category ? category.name : '';
    };

    const deleteItem = async (itemId) => {
        try {
            await axiosInstance.delete(`${API_BASE_URL}/api/items/${itemId}`);
            setItems(items.filter(item => item.id !== itemId));
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const deleteCategory = async (categoryId) => {
        try {
            await axiosInstance.delete(`${API_BASE_URL}/api/categories/${categoryId}`);
            setCategories(categories.filter(category => category.id !== categoryId));
            setItems(items.filter(item => item.category_id !== categoryId));
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };

    const handleCategoryClick = (categoryId) => {
        setSelectedCategoryId(categoryId);
    };

    const handleAddCategory = () => {
        setCurrentCategory(null);
        setIsCategoryFormOpen(true);
    };

    const handleFormSubmitCategory = (category) => {
        fetchItemsAndCategories();
        setIsCategoryFormOpen(false);
    };

    const handleAddItem = () => {
        setCurrentItem(null);
        setIsItemFormOpen(true);
    };

    const handleFormSubmitItem = (item) => {
        fetchItemsAndCategories();
        setIsItemFormOpen(false);
    };

    const filteredItems = selectedCategoryId ? items.filter(item => item.category_id === selectedCategoryId) : [];

    return (
        <div className="menu-restaurant-management-container">
            <h3>Categories</h3>
            <table className="category-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Item Count</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {categories.map(category => (
                        <tr key={category.id}>
                            <td onClick={() => handleCategoryClick(category.id)}>{category.name}</td>
                            <td>{items.filter(item => item.category_id === category.id).length} éléments</td>
                            <td>
                                <button onClick={() => handleEditCategory(category)} className="edit-button">Edit</button>
                                <button onClick={() => deleteCategory(category.id)} className="delete-button">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="button-container">
                <button onClick={handleAddCategory} className="add-category-button">Add Category</button>
                <button onClick={handleAddItem} className="add-item-button">Add Item</button>
            </div>

            {isCategoryFormOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setIsCategoryFormOpen(false)}>&times;</span>
                        <MenuCategoryForm
                            currentCategory={currentCategory}
                            handleFormSubmit={handleFormSubmitCategory}
                            setIsFormOpen={setIsCategoryFormOpen}
                        />
                    </div>
                </div>
            )}

            {selectedCategoryId && (
                <>
                    <h3>Items in {getCategoryName(selectedCategoryId)}</h3>
                    <table className="item-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredItems.map(item => (
                                <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>{item.price}</td>
                                    <td>
                                        <button onClick={() => handleEditItem(item)} className="edit-button">Edit</button>
                                        <button onClick={() => deleteItem(item.id)} className="delete-button">Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}

            {isItemFormOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setIsItemFormOpen(false)}>&times;</span>
                        <MenuItemForm
                            currentItem={currentItem}
                            categories={categories}
                            handleFormSubmit={handleFormSubmitItem}
                            setIsFormOpen={setIsItemFormOpen}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default MenuItemTable;
