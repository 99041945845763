// src/utils/axiosInstance.js

import axios from 'axios';
import config from '../config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// No need to set the Authorization header

export default axiosInstance;
