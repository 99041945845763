// src/components/activities/ActivityItemSelection.js

import React from 'react';
import '../../styles/Activities/Activity.css';

function ActivityItemSelection({ activities, addItemToOrder }) {
    return (
        <div className="activity-item-selection">
            <div className="activity-items">
                {activities.map(activity => (
                    <div key={activity.id} className="activity-item" onClick={() => addItemToOrder(activity, 'activity')}>
                        <h4>{activity.name}</h4>
                        <p>Price: ${activity.price}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ActivityItemSelection;
