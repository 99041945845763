// src/components/User/User.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import UserForm from './UserForm';
import UserTable from './UserTable';
import '../../styles/User/user.css';
import config from '../../config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

function User() {
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axiosInstance.get(`${API_BASE_URL}/api/users`);
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Failed to fetch users.');
        }
    };

    const handleAddUser = () => {
        setCurrentUser(null);
        setIsFormOpen(true);
    };

    const handleEditUser = (user) => {
        setCurrentUser(user);
        setIsFormOpen(true);
    };

    const handleDeleteUser = async (userId) => {
        try {
            await axiosInstance.delete(`${API_BASE_URL}/api/users/${userId}`);
            setUsers(users.filter(user => user.id !== userId));
        } catch (error) {
            console.error('Error deleting user:', error);
            setError('Failed to delete user.');
        }
    };

    const handleFormSubmit = async (user) => {
        try {
            let response;
            if (currentUser) {
                response = await axiosInstance.put(`${API_BASE_URL}/api/users/${currentUser.id}`, user);
                setUsers(users.map(u => u.id === currentUser.id ? response.data : u));
            } else {
                response = await axiosInstance.post(`${API_BASE_URL}/api/users`, user);
                setUsers([...users, response.data]);
            }
            setIsFormOpen(false);
        } catch (error) {
            console.error('Error submitting user:', error);
            setError('Failed to submit user.');
        }
    };

    return (
        <div className="user-management-container">
            {error && <div className="error-message">{error}</div>}
            <div className="add-user-container">
                <button className="add-user-button" onClick={handleAddUser}>Add User</button>
            </div>
            <UserForm
                isVisible={isFormOpen}
                currentUser={currentUser}
                handleFormSubmit={handleFormSubmit}
                setIsFormOpen={setIsFormOpen}
            />
            <UserTable
                users={users}
                handleEditUser={handleEditUser}
                handleDeleteUser={handleDeleteUser}
            />
        </div>
    );
}

export default User;
