import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import MenuItemSelection from './MenuItemSelection';
import ActivityItemSelection from './ActivityItemSelection';
import Basket from '../Basket/Basket';
import CommandList from './CommandList';
import '../../styles/posmanagement/pointOfSale.css';
import config from '../../config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

function POSManager() {
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [activities, setActivities] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [isBasketOpen, setIsBasketOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('menu');
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [clientInfo, setClientInfo] = useState({ name: '', firstname: '', number: '', country: '' });
    const [currentOrderId, setCurrentOrderId] = useState(null);

    useEffect(() => {
        fetchItems();
        fetchCategories();
        fetchActivities();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await axiosInstance.get(`${API_BASE_URL}/api/items`);
            setItems(response.data);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axiosInstance.get(`${API_BASE_URL}/api/categories`);
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchActivities = async () => {
        try {
            const response = await axiosInstance.get(`${API_BASE_URL}/api/activities`);
            setActivities(response.data);
        } catch (error) {
            console.error('Error fetching activities:', error);
        }
    };

    const addItemToOrder = (item, type) => {
        setOrderItems(prevOrderItems => {
            const existingItem = prevOrderItems.find(orderItem => orderItem.id === item.id && orderItem.type === type);
            if (existingItem) {
                return prevOrderItems.map(orderItem =>
                    orderItem.id === item.id && orderItem.type === type ? { ...orderItem, quantity: orderItem.quantity + 1 } : orderItem
                );
            } else {
                return [...prevOrderItems, { ...item, type, quantity: 1 }];
            }
        });
        setIsBasketOpen(true);  // Ensure basket is open whenever an item is added
    };

    const removeItemFromOrder = (index) => {
        setOrderItems(prevOrderItems => prevOrderItems.filter((_, i) => i !== index));
    };

    const finalizeOrder = () => {
        setOrderItems([]);
        setClientInfo({ name: '', firstname: '', number: '', country: '' });
        setPaymentMethod(null);
        setCurrentOrderId(null);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const reopenCommand = async (command) => {
        try {
            const response = await axiosInstance.get(`${API_BASE_URL}/api/orders/${command.id}`);
            const order = response.data;

            setOrderItems([
                ...order.items.map(item => ({
                    type: 'item',
                    id: item.item_id,
                    name: item.name,
                    price: item.price,
                    quantity: item.quantity
                })),
                ...order.activities.map(activity => ({
                    type: 'activity',
                    id: activity.activity_id,
                    name: activity.name,
                    price: activity.price,
                    quantity: activity.quantity
                }))
            ]);

            setClientInfo({
                id: order.customer_id || '',
                name: order.customer_name || '',
                firstname: order.customer_firstname || '',
                number: order.customer_number || '',
                country: order.customer_country || 'Burkina Faso'
            });

            setPaymentMethod(order.payment_method);
            setCurrentOrderId(order.id);
            setIsBasketOpen(true);
        } catch (error) {
            console.error('Error reopening command:', error);
        }
    };

    const toggleBasket = () => {
        setIsBasketOpen(!isBasketOpen);
    };

    return (
        <div className="pos-management-container">
            <div className="tabs">
                <button className={`tab-button ${activeTab === 'menu' ? 'active' : ''}`} onClick={() => handleTabChange('menu')}>Menu Items</button>
                <button className={`tab-button ${activeTab === 'activity' ? 'active' : ''}`} onClick={() => handleTabChange('activity')}>Activity</button>
                <button className={`tab-button ${activeTab === 'commands' ? 'active' : ''}`} onClick={() => handleTabChange('commands')}>Command List</button>
            </div>
            <div className="main-content">
                {activeTab === 'menu' && (
                    <div className="menu-item-selection-container">
                        <MenuItemSelection items={items} categories={categories} addItemToOrder={(item) => addItemToOrder(item, 'item')} />
                    </div>
                )}

                {activeTab === 'activity' && (
                    <div className="activity-item-selection-container">
                        <ActivityItemSelection activities={activities} addItemToOrder={(activity) => addItemToOrder(activity, 'activity')} />
                    </div>
                )}

                {activeTab === 'commands' && (
                    <div className="commands-container">
                        <CommandList reopenCommand={reopenCommand} />
                    </div>
                )}

                {isBasketOpen && (
                    <div className="basket-container">
                        <button className="close-basket-button" onClick={toggleBasket}>x</button>
                        <Basket
                            orderItems={orderItems}
                            removeItemFromOrder={removeItemFromOrder}
                            finalizeOrder={finalizeOrder}
                            setPaymentMethod={setPaymentMethod}
                            paymentMethod={paymentMethod}
                            setClientInfo={setClientInfo}
                            clientInfo={clientInfo}
                            toggleBasket={toggleBasket}
                            handleCancelOrder={finalizeOrder} // Pass the finalizeOrder as handleCancelOrder
                            currentOrderId={currentOrderId}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default POSManager;
