// src/components/basket/DiscountForm.js

import React from 'react';

function DiscountForm({ discountType, setDiscountType, discountValue, setDiscountValue }) {
    return (
        <div className="discount-form">
            <label>
                Discount Type:
                <select value={discountType} onChange={(e) => setDiscountType(e.target.value)}>
                    <option value="">Select</option>
                    <option value="price">Montant</option>
                    <option value="percentage">Pourcentage</option>
                </select>
            </label>
            <label>
                Discount Value:
                <input
                    type="number"
                    value={discountValue}
                    onChange={(e) => setDiscountValue(Number(e.target.value))}
                    placeholder={discountType === 'price' ? 'Enter price discount' : 'Enter percentage discount'}
                />
            </label>
        </div>
    );
}

export default DiscountForm;
