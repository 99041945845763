import React, { useState, useEffect } from 'react';

function ActivityForm({ currentActivity, handleFormSubmit, setIsFormOpen }) {
    const [activity, setActivity] = useState({ name: '', price: '' });

    useEffect(() => {
        if (currentActivity) {
            setActivity(currentActivity);
        }
    }, [currentActivity]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setActivity({ ...activity, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleFormSubmit(activity);
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Name:
                <input
                    type="text"
                    name="name"
                    value={activity.name}
                    onChange={handleChange}
                    required
                />
            </label>
            <label>
                Price:
                <input
                    type="number"
                    name="price"
                    value={activity.price}
                    onChange={handleChange}
                    required
                />
            </label>
            <button type="submit">Save</button>
            <button type="button" onClick={() => setIsFormOpen(false)}>Cancel</button>
        </form>
    );
}

export default ActivityForm;
