import React, { useState, useMemo, useEffect } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import '../../styles/posmanagement/pointOfSale.css';
import CustomerForm from '../Customer/customerForm';
import DiscountForm from '../Basket/DiscountForm';
import PaymentMethods from '../Basket/PaymentMethods';
import config from '../../config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

// Function to get the client's timezone offset as a string
function getTimezoneOffsetString() {
    const offset = new Date().getTimezoneOffset();
    const hours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
    const minutes = String(Math.abs(offset) % 60).padStart(2, '0');
    const sign = offset > 0 ? '-' : '+';
    return `${sign}${hours}:${minutes}`;
}

function Basket({
    orderItems = [],
    removeItemFromOrder,
    finalizeOrder,
    paymentMethod,
    setPaymentMethod,
    clientInfo,
    setClientInfo,
    isBasketOpen,
    toggleBasket,
    handleCancelOrder,
    currentOrderId
}) {
    const [isAddCustomerFormOpen, setIsAddCustomerFormOpen] = useState(false);
    const [isDiscountFormOpen, setIsDiscountFormOpen] = useState(false);
    const [discountType, setDiscountType] = useState('');
    const [discountValue, setDiscountValue] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSavingCustomer, setIsSavingCustomer] = useState(false);
    const [submissionMessage, setSubmissionMessage] = useState('');

    const totalAmount = useMemo(() =>
        orderItems.reduce((total, item) => total + (item.price * item.quantity), 0),
        [orderItems]
    );

    const discountedTotal = useMemo(() => {
        switch (discountType) {
            case 'price':
                return totalAmount - discountValue;
            case 'percentage':
                return totalAmount * (1 - discountValue / 100);
            default:
                return totalAmount;
        }
    }, [totalAmount, discountType, discountValue]);

    const submitOrder = async () => {
        setIsSubmitting(true);
        setSubmissionMessage('');

        const orderData = {
            customer_id: clientInfo.id || null,
            order_date: new Date().toISOString().slice(0, 19).replace('T', ' '), // Store in UTC
            total: discountedTotal,
            payment_method: paymentMethod || 'unpaid',
            status: paymentMethod ? 'completed' : 'pending',
            timezone: getTimezoneOffsetString(), // Send timezone offset
            order_items: orderItems.filter(item => item.type === 'item').map(item => ({
                item_id: item.id,
                quantity: item.quantity,
                price: item.price
            })),
            order_activities: orderItems.filter(activity => activity.type === 'activity').map(activity => ({
                activity_id: activity.id,
                quantity: activity.quantity,
                price: activity.price
            }))
        };

        console.log('Submitting order with data:', orderData);

        try {
            const response = currentOrderId
                ? await axiosInstance.put(`${API_BASE_URL}/api/orders/${currentOrderId}`, orderData)
                : await axiosInstance.post(`${API_BASE_URL}/api/orders`, orderData);

            console.log('Command enregistrée avec succès:', response.data);
            setSubmissionMessage(currentOrderId ? 'Commande modifiée avec succes!' : 'Order registered successfully!');
            finalizeOrder();
        } catch (error) {
            console.error('Failed to submit order:', error);
            setSubmissionMessage('Failed to register order. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const clearOrder = () => {
        finalizeOrder();
        setSubmissionMessage('');
    };

    const handleSaveCustomer = async () => {
        setIsSavingCustomer(true);
        try {
            const response = await axiosInstance.post(`${API_BASE_URL}/api/customers`, clientInfo);
            setClientInfo(response.data);
            setSubmissionMessage('Customer saved successfully.');
        } catch (error) {
            console.error('Error saving customer:', error);
            setSubmissionMessage('Failed to save customer.');
        } finally {
            setIsSavingCustomer(false);
        }
    };

    useEffect(() => {
        if (orderItems.length > 0 || clientInfo.name || clientInfo.firstname) {
            setSubmissionMessage('');
        }
    }, [orderItems, clientInfo]);

    const toggleCustomerForm = () => {
        setIsAddCustomerFormOpen(prevState => !prevState);
        setSubmissionMessage('');
    };

    const toggleDiscountForm = () => {
        setIsDiscountFormOpen(prevState => !prevState);
        setSubmissionMessage('');
    };

    return (
        <div className={`basket ${isBasketOpen ? 'open' : ''}`}>
            <h3>Vente</h3>
            <ul className="basket-items">
                {orderItems.map((item, index) => (
                    <li key={index} className="basket-item">
                        <div className="basket-item-info">
                            {item.quantity} x {item.name}
                        </div>
                        <div className="basket-item-actions">
                            <span>F CFA {item.price * item.quantity}</span>
                            <button onClick={() => removeItemFromOrder(index)} className="remove-button">Remove</button>
                        </div>
                    </li>
                ))}
            </ul>
            <div className="basket-summary">
                <p>Total: F CFA {totalAmount}</p>
                <p>Discounted Total: F CFA {discountedTotal}</p>
            </div>
            <PaymentMethods paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />
            <div className="basket-buttons">
                <button onClick={toggleCustomerForm} className="add-customer-button">
                    {isAddCustomerFormOpen ? 'Hide Customer Form' : 'Add Customer'}
                </button>
                <button onClick={toggleDiscountForm} className="apply-discount-button">Apply Discount</button>
            </div>
            {isAddCustomerFormOpen && (
                <CustomerForm
                    clientInfo={clientInfo}
                    setClientInfo={setClientInfo}
                    onCancel={() => setIsAddCustomerFormOpen(false)}
                    onSave={handleSaveCustomer}
                />
            )}
            {isDiscountFormOpen && (
                <DiscountForm
                    discountType={discountType}
                    setDiscountType={setDiscountType}
                    discountValue={discountValue}
                    setDiscountValue={setDiscountValue}
                />
            )}
            <div className="basket-buttons-payment">
                <button
                    onClick={submitOrder}
                    className="pay-button"
                    disabled={isSubmitting || isSavingCustomer}
                >
                    {paymentMethod ? `Payer F CFA ${discountedTotal}` : `Non Payer F CFA ${discountedTotal}`}
                </button>
                <button onClick={clearOrder} className="pay-cancel-button">Cancel</button>
            </div>
            {submissionMessage && <div className="submission-message">{submissionMessage}</div>}
            {isSavingCustomer && <div className="loading">Saving customer...</div>}
        </div>
    );
}

export default Basket;
