import React, { useState, useEffect } from 'react';

function MenuItemSelection({ items, categories, addItemToOrder }) {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        if (selectedCategory) {
            const categoryItems = items.filter(item => item.category_id === selectedCategory.id);
            setFilteredItems(categoryItems);
        } else {
            setFilteredItems([]);
        }
    }, [selectedCategory, items]);

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };

    const handleAddItem = (item) => {
        addItemToOrder(item, 'item');
    };

    return (
        <div className="menu-item-selection">
            <div className="menu-categories">
                {categories.map(category => (
                    <div
                        key={category.id}
                        className={`menu-category ${selectedCategory && selectedCategory.id === category.id ? 'selected' : ''}`}
                        onClick={() => handleCategorySelect(category)}
                    >
                        {category.image && <img src={category.image} alt={category.name} className="category-image" />}
                        <h4>{category.name}</h4>
                    </div>
                ))}
            </div>
            <div className="menu-items">
                {filteredItems.map(item => (
                    <div key={item.id} className="menu-item" onClick={() => handleAddItem(item)}>
                        <h4>{item.name}</h4>
                        <p>Price: F CFA {item.price}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MenuItemSelection;
