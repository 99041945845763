// src/components/restaurant/MenuManager.js

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import MenuItemForm from './menuItemForm';
import MenuItemTable from './menuItemTable';
import '../../styles/Menu/menuManagement.css';
import config from '../../config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

function MenuManager() {
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currentItem, setCurrentItem] = useState(null);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchItemsAndCategories();
    }, []);

    const fetchItemsAndCategories = async () => {
        try {
            const itemsResponse = await axiosInstance.get(`${API_BASE_URL}/api/items`);
            const categoriesResponse = await axiosInstance.get(`${API_BASE_URL}/api/categories`);
            setItems(itemsResponse.data);
            setCategories(categoriesResponse.data);
        } catch (error) {
            console.error('Error fetching items and categories:', error);
            setError('Failed to fetch items and categories.');
        }
    };

    const handleAddItem = () => {
        setCurrentItem(null);
        setIsFormOpen(true);
    };

    const handleEditItem = (item) => {
        setCurrentItem(item);
        setIsFormOpen(true);
    };

    const handleDeleteItem = async (itemId) => {
        try {
            await axiosInstance.delete(`${API_BASE_URL}/api/items/${itemId}`);
            setItems(items.filter(item => item.id !== itemId));
        } catch (error) {
            console.error('Error deleting item:', error);
            setError('Failed to delete item.');
        }
    };

    const handleFormSubmit = async (item) => {
        try {
            if (currentItem) {
                const response = await axiosInstance.put(`${API_BASE_URL}/api/items/${currentItem.id}`, item);
                setItems(items.map(i => i.id === currentItem.id ? response.data : i));
            } else {
                const response = await axiosInstance.post(`${API_BASE_URL}/api/items`, item);
                setItems([...items, response.data]);
            }
            setIsFormOpen(false);
        } catch (error) {
            console.error('Error submitting item form:', error);
            setError('Failed to submit item.');
        }
    };

    return (
        <div className="menu-restaurant-management-container">
            {error && <div className="error-message">{error}</div>}
            <div className="button-container">
                <button onClick={handleAddItem} className="add-item-button">Add Item</button>
            </div>
            <MenuItemTable
                handleEditItem={handleEditItem}
                handleDeleteItem={handleDeleteItem}
            />
            {isFormOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setIsFormOpen(false)}>&times;</span>
                        <MenuItemForm
                            currentItem={currentItem}
                            categories={categories}
                            handleFormSubmit={handleFormSubmit}
                            setIsFormOpen={setIsFormOpen}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default MenuManager;
