import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

function UserForm({ isVisible, currentUser, handleFormSubmit, setIsFormOpen }) {
    const [user, setUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        role: 'caissier', // Default role
        is_active: true,
    });

    useEffect(() => {
        if (currentUser) {
            setUser({
                first_name: currentUser.first_name,
                last_name: currentUser.last_name,
                email: currentUser.email,
                password: '', // Assuming you want the user to enter a new password if editing
                role: currentUser.role,
                is_active: currentUser.is_active,
            });
        } else {
            setUser({
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                role: 'caissier', // Default role
                is_active: true,
            });
        }
    }, [currentUser, isVisible]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser(prev => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setUser(prev => ({ ...prev, [name]: checked }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (currentUser) {
                await axios.put(`${API_BASE_URL}/api/users/${currentUser.id}`, user);
            } else {
                await axios.post(`${API_BASE_URL}/api/users`, user);
            }
            handleFormSubmit(user);
        } catch (error) {
            console.error('Error submitting form:', error);
            console.error(error.response?.data); // Log error response from the server
        }
        setIsFormOpen(false);
    };

    return (
        <div className={`user-form-panel ${isVisible ? 'open' : ''}`}>
            <form onSubmit={handleSubmit} className="user-form">
                <div className="form-content">
                    <label>
                        First Name:
                        <input type="text" name="first_name" value={user.first_name} onChange={handleChange} required />
                    </label>
                    <label>
                        Last Name:
                        <input type="text" name="last_name" value={user.last_name} onChange={handleChange} required />
                    </label>
                    <label>
                        Email:
                        <input type="email" name="email" value={user.email} onChange={handleChange} required />
                    </label>
                    <label>
                        Password:
                        <input type="password" name="password" value={user.password} onChange={handleChange} required={!currentUser} />
                    </label>
                    <label>
                        Role:
                        <select name="role" value={user.role} onChange={handleChange} required>
                            <option value="caissier">Caissier</option>
                            <option value="manager">Manager</option>
                            <option value="owner">Owner</option>
                        </select>
                    </label>
                    <label>
                        Active:
                        <input type="checkbox" name="is_active" checked={user.is_active} onChange={handleCheckboxChange} />
                    </label>
                </div>
                <div className="buttons-container">
                    <button type="submit" className="submit-button">Submit</button>
                    <button type="button" className="cancel-button" onClick={() => setIsFormOpen(false)}>Cancel</button>
                </div>
            </form>
        </div>
    );
}

export default UserForm;
