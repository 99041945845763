// src/components/restaurant/MenuCategoryForm.js

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import config from '../../config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

function MenuCategoryForm({ currentCategory, handleFormSubmit, setIsFormOpen }) {
    const [category, setCategory] = useState({ name: '' });

    useEffect(() => {
        if (currentCategory) {
            setCategory(currentCategory);
        }
    }, [currentCategory]);

    const handleChange = (e) => {
        setCategory({ ...category, name: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (currentCategory) {
                await axiosInstance.put(`${API_BASE_URL}/api/categories/${currentCategory.id}`, category);
            } else {
                await axiosInstance.post(`${API_BASE_URL}/api/categories`, category);
            }
            handleFormSubmit(category);
            setCategory({ name: '' }); // Reset form after submission
        } catch (error) {
            console.error('Error submitting form:', error);
        }
        setIsFormOpen(false);
    };

    return (
        <div className="category-form-container">
            <form onSubmit={handleSubmit} className="category-form">
                <label>
                    Category Name:
                    <input
                        type="text"
                        value={category.name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <div className="form-controls">
                    <button type="submit" className="submit-button">Submit</button>
                    <button type="button" className="cancel-button" onClick={() => setIsFormOpen(false)}>Cancel</button>
                </div>
            </form>
        </div>
    );
}

export default MenuCategoryForm;
