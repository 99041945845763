import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/Home/Home.css';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import config from '../../config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

function Home() {
    const ref = useRef(null);
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [totalSales, setTotalSales] = useState(0);
    const [paymentMethods, setPaymentMethods] = useState({});
    const [username, setUsername] = useState('');
    const [dateRange, setDateRange] = useState('Today');
    const [currentTime, setCurrentTime] = useState(new Date());

    const fetchSalesData = async (start, end) => {
        try {
            const response = await axiosInstance.get(`${API_BASE_URL}/api/sales/data`, {
                params: {
                    startDate: start.toISOString(),
                    endDate: end.toISOString(),
                }
            });
            setTotalSales(response.data.totalSales || 0);
            setPaymentMethods(response.data.paymentMethods || {});
        } catch (error) {
            console.error('Error fetching sales data:', error);
        }
    };

    useEffect(() => {
        updateDateRange(dateRange);
    }, [dateRange]);

    useEffect(() => {
        fetchSalesData(startDate, endDate);
    }, [startDate, endDate]);

    useEffect(() => {
        const fetchUserData = async () => {
            const storedUsername = localStorage.getItem('username');
            const userId = localStorage.getItem('userId');
            if (storedUsername) {
                console.log("Using stored username:", storedUsername);
                setUsername(storedUsername);
            } else if (userId) {
                try {
                    const response = await axiosInstance.get(`${API_BASE_URL}/api/users/me`, {
                        params: { userId } // Send userId as query parameter
                    });
                    console.log("API response:", response.data);
                    if (response.data && response.data.first_name) {
                        console.log("Fetched username:", response.data.first_name);
                        setUsername(response.data.first_name);
                        localStorage.setItem('username', response.data.first_name);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            } else {
                console.log("No stored username or userId found.");
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(timer); // Cleanup interval on component unmount
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('authenticated');
        localStorage.removeItem('username');
        localStorage.removeItem('userId');
        navigate('/login');
    };

    const updateDateRange = (value) => {
        const now = new Date();
        let start, end;

        switch (value) {
            case 'Today':
                start = new Date(now.setHours(0, 0, 0, 0));
                end = new Date(now.setHours(23, 59, 59, 999));
                break;
            case 'Yesterday':
                start = new Date(now);
                start.setDate(now.getDate() - 1);
                start.setHours(0, 0, 0, 0);
                end = new Date(start);
                end.setHours(23, 59, 59, 999);
                break;
            case 'This Week':
                start = new Date(now);
                const dayOfWeek = start.getDay() === 0 ? 7 : start.getDay(); // Adjust if today is Sunday
                start.setDate(start.getDate() - (dayOfWeek - 1)); // Move back to the last Monday
                start.setHours(0, 0, 0, 0);
                end = new Date(start);
                end.setDate(start.getDate() + 6); // Sunday
                end.setHours(23, 59, 59, 999);
                break;
            case 'This Month':
                start = new Date(now.getFullYear(), now.getMonth(), 1);
                end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                end.setHours(23, 59, 59, 999);
                break;
            case 'Last Month':
                start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                end = new Date(now.getFullYear(), now.getMonth(), 0);
                end.setHours(23, 59, 59, 999);
                break;
            case 'Custom':
                start = new Date(startDate);
                end = new Date(endDate);
                break;
            default:
                start = new Date();
                end = new Date();
        }

        setStartDate(start);
        setEndDate(end);
    };

    const handleDateRangeChange = (event) => {
        setDateRange(event.target.value);
    };

    return (
        <div className="home" ref={ref}>
            <div className="user-greeting">
                Hello, <strong>{username || 'Guest'}</strong>
                <button className="logout-button" onClick={handleLogout}>Logout</button>
            </div>
            <div className="welcome-section">
                <h1>Welcome to Arcade POS</h1>
                <p>Your one-stop solution for managing arcade transactions efficiently.</p>
                <p>Current Time: <strong>{currentTime.toLocaleTimeString()}</strong></p>
            </div>
            <div className="content-section">
                <div className="sales-section">
                    <h2>Total Sales</h2>
                    <p className="total-sales">Total sales from <strong>{startDate.toLocaleString()}</strong> to <strong>{endDate.toLocaleString()}</strong>: <strong>F CFA {totalSales.toLocaleString()}</strong></p>
                    <div className="date-picker">
                        <select value={dateRange} onChange={handleDateRangeChange} className="date-range-selector">
                            <option value="Today">Today</option>
                            <option value="Yesterday">Yesterday</option>
                            <option value="This Week">This Week</option>
                            <option value="This Month">This Month</option>
                            <option value="Last Month">Last Month</option>
                            <option value="Custom">Custom Date</option>
                        </select>
                        {dateRange === 'Custom' && (
                            <>
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setStartDate(new Date(date.setHours(0, 0, 0, 0)))}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="MMMM d, yyyy"
                                    className="datepicker"
                                />
                                <DatePicker
                                    selected={endDate}
                                    onChange={date => setEndDate(new Date(date.setHours(23, 59, 59, 999)))}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="MMMM d, yyyy"
                                    className="datepicker"
                                />
                            </>
                        )}
                    </div>
                    <div className="method-sales">
                        <h3>Sales by Payment Method</h3>
                        <ul>
                            {Object.entries(paymentMethods).map(([method, total], index) => (
                                <li key={index}>
                                    <span>{method}</span>
                                    <span>F CFA {total.toLocaleString()}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="graph-section">
                    <h2>Sales Graph</h2>
                    <p>Graph displaying sales data over the selected date range will go here.</p>
                </div>
                <div className="features-section">
                    <h2>New Features</h2>
                    <ul>
                        <li>Feature 1: Improved transaction management.</li>
                        <li>Feature 2: Enhanced user interface.</li>
                        <li>Feature 3: New reporting tools.</li>
                    </ul>
                </div>
                <div className="announcements-section">
                    <h2>Announcements</h2>
                    <ul>
                        <li>Announcement 1: Scheduled maintenance on July 20th.</li>
                        <li>Announcement 2: New feature release on August 5th.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Home;
