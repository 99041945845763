import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/posmanagement/commandList.css';
import config from '../../config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

// Function to convert UTC date strings to local time strings
function convertUTCToLocal(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString(); // Convert to local date and time string
}

function CommandList({ reopenCommand }) {
    const [commands, setCommands] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [searchQuery, setSearchQuery] = useState('');
    const [filterType, setFilterType] = useState('all');

    // Function to fetch commands based on filters
    const fetchCommands = useCallback(async () => {
        try {
            const response = await axiosInstance.get(`${API_BASE_URL}/api/orders`, {
                params: {
                    date: selectedDate.toISOString().split('T')[0],
                    type: filterType !== 'all' ? filterType : undefined, // Only send type if it's not 'all'
                    search: searchQuery || undefined, // Only send search if there's a query
                },
            });
            setCommands(response.data);
        } catch (error) {
            console.error('Error fetching commands:', error);
        }
    }, [selectedDate, filterType, searchQuery]);

    useEffect(() => {
        fetchCommands();
    }, [fetchCommands]);

    const handlePreviousDay = () => {
        setSelectedDate((prevDate) => {
            const newDate = new Date(prevDate);
            newDate.setDate(newDate.getDate() - 1);
            return newDate;
        });
    };

    const handleNextDay = () => {
        setSelectedDate((prevDate) => {
            const newDate = new Date(prevDate);
            newDate.setDate(newDate.getDate() + 1);
            return newDate;
        });
    };

    const formatDate = (date) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('fr-FR', options);
    };

    const handleCommandClick = (command) => {
        reopenCommand(command);
    };

    const handleFilterChange = (event) => {
        setFilterType(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <div className="command-list">
            <div className="command-list-header">
                <button className="nav-button" onClick={handlePreviousDay}>{"<"}</button>
                <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd MMMM yyyy"
                    customInput={<h3>{formatDate(selectedDate)}</h3>}
                />
                <button className="nav-button" onClick={handleNextDay}>{">"}</button>
            </div>
            <div className="filters">
                <select value={filterType} onChange={handleFilterChange} className="filter-selector">
                    <option value="all">All</option>
                    <option value="Activity">Activity</option>
                    <option value="Restaurant">Restaurant</option>
                </select>
                <input
                    type="text"
                    placeholder="Search a command"
                    className="search-input"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </div>
            <table className="command-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Status</th>
                        <th>Client</th>
                        <th>Hour</th>
                        <th>Mode</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {commands.map((command) => (
                        <tr key={command.id} onClick={() => handleCommandClick(command)}>
                            <td>{command.id}</td>
                            <td>{command.status}</td>
                            <td>{command.customer_name ? `${command.customer_name} ${command.customer_firstname}` : 'Anonymous'}</td>
                            <td>{convertUTCToLocal(command.order_date)}</td>
                            <td>{command.payment_method}</td>
                            <td>F CFA {command.total.toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default CommandList;
