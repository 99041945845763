import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/customer/Customer.css';
import config from '../../config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
    //... (other countries)
    "Zimbabwe"
];

function CustomerForm({ clientInfo, setClientInfo, onCancel, onSave }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [matchingCustomers, setMatchingCustomers] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [searchError, setSearchError] = useState('');

    useEffect(() => {
        if (searchQuery) {
            setIsSearching(true);
            setSearchError('');
            axios.get(`${API_BASE_URL}/api/customers/search`, { params: { name: searchQuery } })
                .then(response => {
                    setMatchingCustomers(response.data);
                    setIsSearching(false);
                    if (response.data.length === 0) {
                        setSearchError('No customers found.');
                    }
                })
                .catch(error => {
                    setIsSearching(false);
                    if (error.response && error.response.status === 404) {
                        setSearchError('No customers found.');
                    } else {
                        console.error('Error fetching customers:', error);
                        setSearchError('Error fetching customers. Please try again.');
                    }
                });
        } else {
            setMatchingCustomers([]);
            setSearchError('');
        }
    }, [searchQuery]);

    const handleSelectCustomer = (customer) => {
        setClientInfo(customer);
        setSearchQuery('');
        setMatchingCustomers([]);
    };

    return (
        <div className="customer-form">
            <h3>Customer Information</h3>
            <input
                type="text"
                placeholder="Search customer by name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            {isSearching && <p>Searching...</p>}
            {searchError && <p className="error-message">{searchError}</p>}
            <ul className="customer-search-results">
                {matchingCustomers.map(customer => (
                    <li key={customer.id} onClick={() => handleSelectCustomer(customer)}>
                        {customer.name} {customer.firstname}
                    </li>
                ))}
            </ul>
            <input
                type="text"
                placeholder="Name"
                value={clientInfo.name || ''}
                onChange={(e) => setClientInfo({ ...clientInfo, name: e.target.value })}
            />
            <input
                type="text"
                placeholder="First Name"
                value={clientInfo.firstname || ''}
                onChange={(e) => setClientInfo({ ...clientInfo, firstname: e.target.value })}
            />
            <input
                type="text"
                placeholder="Number"
                value={clientInfo.number || ''}
                onChange={(e) => setClientInfo({ ...clientInfo, number: e.target.value })}
            />
            <select
                value={clientInfo.country || "Burkina Faso"} // Set default to "Burkina Faso"
                onChange={(e) => setClientInfo({ ...clientInfo, country: e.target.value })}
            >
                {countries.map((country) => (
                    <option key={country} value={country}>{country}</option>
                ))}
            </select>
            <button className="save-customer" onClick={onSave}>Save</button>
            <button className="cancel-customer" onClick={onCancel}>Cancel</button>
        </div>
    );
}

export default CustomerForm;
