import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { NavLink, useNavigate } from 'react-router-dom';
import '../../styles/Navbar/Navbar.css';
import logo from './img/logo.png';

function Navbar({ authenticated, setAuthenticated }) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isSettingsSubMenuOpen, setIsSettingsSubMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleStateChange = (state) => {
        setMenuOpen(state.isOpen);
        if (!state.isOpen) {
            setIsSettingsSubMenuOpen(false); // Close submenu when the main menu closes
        }
    };

    const toggleSettingsSubMenu = () => {
        setIsSettingsSubMenuOpen(!isSettingsSubMenuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
        setIsSettingsSubMenuOpen(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setAuthenticated(false);
        closeMenu();
        navigate('/login');
    };

    return (
        <div>
            <Menu
                isOpen={menuOpen}
                onStateChange={handleStateChange}
                width={300}
                left
            >
                <div className="logo-container">
                    <img className="logo-menu" src={logo} alt="Logo" />
                </div>
                <NavLink to="/" className="navbar-item" activeClassName="active" onClick={closeMenu}>Home</NavLink>
                <NavLink to="/menu/sell" className="navbar-item" activeClassName="active" onClick={closeMenu}>Point of Sale</NavLink>
                {authenticated ? (
                    <>
                        <div className="navbar-item" onClick={toggleSettingsSubMenu}>Settings</div>
                        {isSettingsSubMenuOpen && (
                            <div className="submenu">
                                <NavLink to="/menu/manage" className="navbar-item subnavbar-item" activeClassName="active" onClick={closeMenu}>Manage Menu</NavLink>
                                <NavLink to="/activity/manage" className="navbar-item subnavbar-item" activeClassName="active" onClick={closeMenu}>Manage Activity</NavLink>
                                <NavLink to="/staff" className="navbar-item subnavbar-item" activeClassName="active" onClick={closeMenu}>Manage Staff</NavLink>
                            </div>
                        )}
                        <button className="navbar-item" onClick={handleLogout}>Logout</button>
                    </>
                ) : (
                    <NavLink to="/login" className="navbar-item" activeClassName="active" onClick={closeMenu}>Login</NavLink>
                )}
            </Menu>
        </div>
    );
}

export default Navbar;
