import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import ErrorBoundary from './components/ErrorBoundary';
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import Navbar from './components/Navbar/Navbar';
import User from './components/User/User'; // Ensure this is the correct path to the User component
import MenuManager from './components/Menu/menuManager';
import POSManager from './components/posmanagement/PointOfSale';
import ActivityManager from './components/Activities/activityManager';
import Settings from './components/Settings/Settings';
import config from './config';

const API_BASE_URL = config.REMOTE_CLIENT_API;

function AppWithNavbar() {
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [activities, setActivities] = useState([]);
    const [commands, setCommands] = useState([]);
    const [authenticated, setAuthenticated] = useState(localStorage.getItem('authenticated') === 'true');

    useEffect(() => {
        fetchItems();
        fetchCategories();
        fetchActivities();
        fetchCommands();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/items`);
            setItems(response.data);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/categories`);
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchActivities = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/activities`);
            setActivities(response.data);
        } catch (error) {
            console.error('Error fetching activities:', error);
        }
    };

    const fetchCommands = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/orders`);
            setCommands(response.data);
        } catch (error) {
            console.error('Error fetching commands:', error);
        }
    };

    return (
        <Router>
            <NavbarLayout
                authenticated={authenticated}
                setAuthenticated={setAuthenticated}
                items={items}
                categories={categories}
                activities={activities}
                commands={commands}
            />
        </Router>
    );
}

function NavbarLayout({
    authenticated,
    setAuthenticated,
    items,
    categories,
    activities,
    commands
}) {
    const location = useLocation();
    const showNavbar = !['/login'].includes(location.pathname);

    return (
        <>
            {showNavbar && <Navbar authenticated={authenticated} setAuthenticated={setAuthenticated} />}
            <Routes>
                <Route path="/" element={authenticated ? <Home /> : <Navigate to="/login" />} />
                <Route path="/login" element={<Login setAuthenticated={setAuthenticated} />} />
                <Route path="/staff" element={authenticated ? <User /> : <Navigate to="/login" />} />
                <Route path="/menu/manage" element={authenticated ? (
                    <MenuManager
                        items={items}
                        categories={categories}
                    />
                ) : <Navigate to="/login" />} />
                <Route path="/menu/sell" element={
                    <POSManager
                        items={items}
                        categories={categories}
                        activities={activities}
                        commands={commands}
                    />
                } />
                <Route path="/activity/manage" element={authenticated ? (
                    <ActivityManager
                        activities={activities}
                    />
                ) : <Navigate to="/login" />} />
                <Route path="/settings/*" element={authenticated ? <Settings /> : <Navigate to="/login" />} />
            </Routes>
        </>
    );
}

function App() {
    return (
        <ErrorBoundary>
            <AppWithNavbar />
        </ErrorBoundary>
    );
}

export default App;